<template>
  <div>
    <v-banner class="mb-10">
      <h2>Active User</h2>
    </v-banner>

    <v-card flat>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="data"
        :loading="loading"
        :search="search"
        item-key="name"
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.nomor`]="{ item }">
          {{
            data
              .map(function (x) {
                return x.userName;
              })
              .indexOf(item.userName) + 1
          }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>


<script>
import Cookie from "@/helper/cookie.js";

export default {
  data: () => ({
    token: Cookie.get("token"),
    loading: true,
    session: "",
    search: "",

    data: [],

    headers: [
      {
        text: "No",
        value: "nomor",
        width: "50px",
        align: "center",
        sortable: false,
      },
      { text: "ID Client", align: "start", value: "idClinet" },
      { text: "User Name", align: "start", value: "userName" },
    ],
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.getData();
  },

  methods: {
    getData() {
      this.loading = true;
      const url = process.env.VUE_APP_AUTH + "service/activity";
      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.data = response.data.mapData;
          this.loading = false;
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    viewItem(item) {
      this.$router
        .push({ path: "/security/" + item.id, query: { unor: item.nama } })
        .catch(() => {});
    },
  },
};
</script>
